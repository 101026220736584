import type { KioskModifierGroup } from '../../models';

type KioskModifierGroupWithoutComputedProperties = Omit<
  KioskModifierGroup,
  'hasOneRequiredVisibleModifier' | 'hasOneFlattenModifier'
>;

export const hasOneRequiredVisibleModifier = (
  group: KioskModifierGroupWithoutComputedProperties
) => {
  return (
    group.modifiers.filter((modifier) => !modifier.isInvisible).length === 1 && group.selectMin > 0
  );
};

export const hasOneFlattenModifier = (group: KioskModifierGroupWithoutComputedProperties) => {
  const visibleModifiers = group.modifiers.filter((modifier) => !modifier.isInvisible);
  return (
    visibleModifiers.length === 1 && // modifier should be visible
    group.selectMin === 0 && // modifier should be optional
    // temporary hack to make sure to add the above typing.. this is fixed on develop
    (visibleModifiers[0] as unknown as { price: number }).price === 0 && // modifier should have no price
    visibleModifiers[0].menuItem.modifierGroups.length > 0 &&
    visibleModifiers[0].menuItem.modifierGroups.some(
      ({ name }) => name.toLowerCase() === group.name.toLowerCase() // modifier child should have different name
    )
  );
};
