import { State, assign, createMachine, interpret, type AnyEventObject } from 'xstate';

const routingMachine = createMachine({
  id: 'promise',
  predictableActionArguments: true,
  preserveActionOrder: true,
  context: {
    currentPage: '',
    currentPopup: '',
  },
  initial: 'core',
  tsTypes: {} as import('./routing_machine.typegen').Typegen0,
  schema: {},
  states: {
    core: {
      type: 'parallel',
      states: {
        page: {
          initial: 'logged_out',
          on: {
            RESET_TO_START: {
              target: 'page.logged_in.start.order_start',
              actions: assign({
                currentPage: '',
                currentPopup: '',
              }),
            },
          },
          states: {
            // eslint-disable-next-line camelcase
            logged_out: {
              tags: 'LOGGED_OUT',
              on: {
                REGISTERED: {
                  target: 'logged_in.start.admin',
                },
                REGISTERING: {
                  target: 'registering',
                },
                LOGGED_IN: {
                  target: 'logged_in',
                },
              },
            },
            registering: {
              tags: 'REGISTERING',
              on: {
                REGISTERED: {
                  target: 'logged_in.start.admin',
                },
                FAILED: {
                  target: 'logged_out',
                },
              },
            },
            // eslint-disable-next-line camelcase
            logged_in: {
              tags: 'LOGGED_IN',
              on: {
                LOYALTY_ACCOUNT: {
                  target: '.session.loyalty_account',
                },
                LOG_OUT: {
                  target: 'logged_out',
                },
              },
              initial: 'start',
              states: {
                start: {
                  tags: 'NOT_IN_SESSION',
                  initial: 'order_start',
                  on: {
                    ADMIN: {
                      target: '.admin',
                    },
                    ORDER_START: {
                      target: '.order_start',
                    },
                    START: {
                      target: 'session',
                    },
                  },
                  states: {
                    idle: {},
                    admin: {
                      tags: 'ADMIN',
                    },
                    // eslint-disable-next-line camelcase
                    loyalty_account: {
                      tags: 'LOYALTY_ACCOUNT',
                    },
                    // eslint-disable-next-line camelcase
                    order_start: {
                      tags: 'ORDER_START',
                      on: {
                        PICKUP_PAYMENT: {
                          target: '.pickup_payment_session',
                        },
                      },
                      states: {
                        // eslint-disable-next-line camelcase
                        pickup_payment_session: {
                          tags: 'IN_PICKUP_PAYMENT_SESSION',
                          initial: 'checkout',
                          on: {
                            CHECKOUT: {
                              target: '.checkout',
                            },
                          },
                          states: {
                            checkout: {
                              tags: 'CHECKING_OUT',
                              initial: 'PAYING',
                              states: {
                                PAYING: {
                                  tags: 'PAYING',
                                  initial: 'SELECT_PAYMENT',
                                  on: {
                                    SELECT_PAYMENT: { target: '.SELECT_PAYMENT' },
                                    GIFTCARD: { target: '.GIFTCARD' },
                                    CARD: { target: '.CARD' },
                                    STORED_VALUE: { target: '.STORED_VALUE' },
                                    CASH: { target: '.CASH' },
                                    PAY_AT_COUNTER: { target: '.PAY_AT_COUNTER' },
                                  },
                                  states: {
                                    SELECT_PAYMENT: {
                                      tags: 'SELECT_PAYMENT',
                                    },
                                    GIFTCARD: {
                                      tags: 'GIFTCARD',
                                    },
                                    CARD: {
                                      tags: 'CARD',
                                    },
                                    STORED_VALUE: {
                                      tags: 'STORED_VALUE',
                                    },
                                    CASH: {
                                      tags: ['CASH'],
                                    },
                                    PAY_AT_COUNTER: {
                                      tags: ['PAY_AT_COUNTER'],
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
                session: {
                  tags: 'IN_SESSION',
                  initial: 'menu',
                  on: {
                    CHECKOUT: {
                      target: '.checkout',
                    },
                    MENU: {
                      target: '.menu',
                    },
                    REVIEW_ORDER: {
                      target: '.menu.REVIEWING',
                    },
                  },
                  states: {
                    menu: {
                      initial: 'ORDERING',
                      states: {
                        ORDERING: { tags: 'ORDERING' },
                        REVIEWING: { tags: 'REVIEWING' },
                      },
                    },
                    checkout: {
                      tags: 'CHECKING_OUT',
                      initial: 'PAYING',
                      on: {
                        PAY: {
                          target: '.PAYING',
                        },
                        PAYMENT_SELECTION: {
                          target: '.PAYMENT_SELECTION',
                        },
                        TIPS: { target: '.TIPS' },
                        PROCESS_PAYMENT: { target: '.PAYMENT_PROCESSING' },
                        POINTS_EARNED: { target: '.POINTS_EARNED' },
                        ORDER_CONFIRMATION: { target: '.ORDER_CONFIRMATION' },
                        PAYMENT_SUCCESS: { target: '.PAYMENT_SUCCESS' },
                        PAID: { target: '.PAID' },
                        NOTPAID: { target: '.NOTPAID' },
                      },
                      states: {
                        PAYMENT_SELECTION: {
                          tags: 'SELECTING_PAYMENT',
                        },
                        TIPS: {
                          tags: 'TIPS',
                        },
                        PAYING: {
                          tags: 'PAYING',
                          initial: 'SELECT_PAYMENT',
                          on: {
                            SELECT_PAYMENT: { target: '.SELECT_PAYMENT' },
                            GIFTCARD: { target: '.GIFTCARD' },
                            CARD: { target: '.CARD' },
                            STORED_VALUE: { target: '.STORED_VALUE' },
                            CASH: { target: '.CASH' },
                            PAY_AT_COUNTER: { target: '.PAY_AT_COUNTER' },
                          },
                          states: {
                            SELECT_PAYMENT: {
                              tags: 'SELECT_PAYMENT',
                            },
                            GIFTCARD: {
                              tags: 'GIFTCARD',
                            },
                            CARD: {
                              tags: 'CARD',
                            },
                            STORED_VALUE: {
                              tags: 'STORED_VALUE',
                            },
                            CASH: {
                              tags: ['CASH'],
                            },
                            PAY_AT_COUNTER: {
                              tags: ['PAY_AT_COUNTER'],
                            },
                          },
                        },
                        PAYMENT_PROCESSING: {
                          tags: 'PAYMENT_PROCESSING',
                        },
                        POINTS_EARNED: {
                          tags: 'POINTS_EARNED',
                        },
                        PAYMENT_SUCCESS: {
                          tags: 'PAYMENT_SUCCESS',
                        },
                        ORDER_CONFIRMATION: {
                          tags: 'ORDER_CONFIRMATION',
                        },
                        PAID: {
                          tags: 'PAID',
                        },
                        NOTPAID: {
                          tags: 'NOTPAID',
                        },
                      },
                    },
                    // eslint-disable-next-line camelcase
                    checked_out: {
                      tags: 'CHECKED_OUT',
                      states: {
                        paid: {
                          tags: 'PAID',
                        },
                        // eslint-disable-next-line camelcase
                        points_earned: {
                          tags: 'POINTS_EARNED',
                        },
                        confirmation: {
                          tags: 'ORDER_CONFIRMATION',
                        },
                      },
                    },
                    // eslint-disable-next-line camelcase
                    loyalty_account: {
                      tags: 'LOYALTY_ACCOUNT',
                    },
                  },
                },
              },
            },
          },
        },
        popup: {
          initial: 'empty',
          type: 'parallel',
          on: {
            RESET_TO_START: {
              target: [
                '.message.closed',
                '.order_upsell.closed',
                '.item_upsell.closed',
                '.makemeal.closed',
                '.item_special_request.closed',
                '.error_modal.closed',
                '.loyalty_modal.closed',
                '.discount_detail_modal.closed',
                '.combo_builder.closed',
                '.discount_code_modal.closed',
                '.pay_at_counter_modal.closed',
                '.reward_code_modal.closed',
                '.customize_item_modal.closed',
                '.subtotal_zero_with_reward_modal.closed',
              ],
            },
          },
          states: {
            message: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                OPEN_MESSAGES: {
                  target: '.visible',
                },
                CLOSE_MESSAGES: {
                  target: '.closed',
                },
              },
            },
            // eslint-disable-next-line camelcase
            order_upsell: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                OPEN_ORDER_UPSELLS: {
                  target: '.visible',
                },
                CLOSE_ORDER_UPSELLS: {
                  target: '.closed',
                },
              },
            },
            // eslint-disable-next-line camelcase
            item_upsell: {
              initial: 'closed',
              states: {
                visible: {},
                reopen: {},
                closed: {},
              },
              on: {
                CLOSE_ITEM_UPSELLS: {
                  target: '.closed',
                },
                OPEN_ITEM_UPSELLS: {
                  target: '.visible',
                },
                REOPEN_ITEM_UPSELLS: {
                  target: '.reopen',
                },
              },
            },
            makemeal: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                CLOSE_MAKEMEAL: {
                  target: '.closed',
                },
                OPEN_MAKEMEAL: {
                  target: '.visible',
                },
              },
            },
            // eslint-disable-next-line camelcase
            item_special_request: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                CLOSE_ITEM_SPECIAL_REQUEST: {
                  target: '.closed',
                },
                OPEN_ITEM_SPECIAL_REQUEST: {
                  target: '.visible',
                },
              },
            },

            // eslint-disable-next-line camelcase
            error_modal: {
              initial: 'closed',
              states: {
                // eslint-disable-next-line camelcase
                submit_error: {
                  tags: 'submit_error',
                },
                // eslint-disable-next-line camelcase
                calculate_error: {
                  tags: 'calculate_error',
                },
                // eslint-disable-next-line camelcase
                generic_error: {
                  tags: 'generic_error',
                },
                // eslint-disable-next-line camelcase
                proxy_pos_error: {
                  tags: 'proxy_pos_error',
                },
                // eslint-disable-next-line camelcase
                refresh_error: {
                  tags: 'refresh_error',
                },
                // eslint-disable-next-line camelcase
                unlinked_error: {
                  tags: 'unlinked_error',
                },
                // eslint-disable-next-line camelcase
                payment_error: {
                  tags: 'payment_error',
                },
                closed: {},
              },
              on: {
                CLOSE_ERROR_MODAL: {
                  target: '.closed',
                },
                OPEN_CALCULATE_ERROR_MODAL: {
                  target: '.calculate_error',
                },
                OPEN_SUBMIT_ERROR_MODAL: {
                  target: '.submit_error',
                },
                OPEN_GENERIC_ERROR_MODAL: {
                  target: '.generic_error',
                },
                OPEN_PROXY_POS_ERROR_MODAL: {
                  target: '.proxy_pos_error',
                },
                OPEN_REFRESH_ERROR_MODAL: {
                  target: '.refresh_error',
                },
                OPEN_UNLINKED_ERROR_MODAL: {
                  target: '.unlinked_error',
                },
                // eslint-disable-next-line camelcase
                OPEN_PAYMENT_ERROR_MODAL: {
                  target: '.payment_error',
                },
              },
            },
            // eslint-disable-next-line camelcase
            loyalty_modal: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                CLOSE_LOYALTY_MODAL: {
                  target: '.closed',
                },
                OPEN_LOYALTY_MODAL: {
                  target: '.visible',
                },
              },
            },
            // eslint-disable-next-line camelcase
            discount_detail_modal: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                CLOSE_DISCOUNT_DETAIL_MODAL: {
                  target: '.closed',
                },
                OPEN_DISCOUNT_DETAIL_MODAL: {
                  target: '.visible',
                },
              },
            },
            // eslint-disable-next-line camelcase
            combo_builder: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                COMBO_BUILDER_CLOSE: {
                  target: '.closed',
                },
                COMBO_BUILDER_OPEN: {
                  target: '.visible',
                },
              },
            },

            // eslint-disable-next-line camelcase
            discount_code_modal: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                CLOSE_DISCOUNT_CODE_MODAL: {
                  target: '.closed',
                },
                OPEN_DISCOUNT_CODE_MODAL: {
                  target: '.visible',
                },
              },
            },

            // eslint-disable-next-line camelcase
            pay_at_counter_modal: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                OPEN_PAY_AT_COUNTER_MODAL: {
                  target: '.visible',
                },
                CLOSE_PAY_AT_COUNTER_MODAL: {
                  target: '.closed',
                },
              },
            },
            // eslint-disable-next-line camelcase
            reward_code_modal: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                CLOSE_REWARD_CODE_MODAL: {
                  target: '.closed',
                },
                OPEN_REWARD_CODE_MODAL: {
                  target: '.visible',
                },
              },
            },
            // eslint-disable-next-line camelcase
            customize_item_modal: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                OPEN_CUSTOMIZE_ITEM: {
                  target: '.visible',
                },
                CLOSE_CUSTOMIZE_ITEM: {
                  target: '.closed',
                },
              },
            },
            // eslint-disable-next-line camelcase
            subtotal_zero_with_reward_modal: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                OPEN_SUBTOTAL_ZERO_WITH_REWARD: {
                  target: '.visible',
                },
                CLOSE_SUBTOTAL_ZERO_WITH_REWARD: {
                  target: '.closed',
                },
              },
            },
            freeItemModal: {
              initial: 'closed',
              states: {
                visible: {},
                closed: {},
              },
              on: {
                OPEN_FREE_ITEM: {
                  target: '.visible',
                },
                CLOSE_FREE_ITEM: {
                  target: '.closed',
                },
              },
            },
          },
        },
      },
    },
  },
});

export type RoutingState = State<
  {
    currentPage: string;
    currentPopup: string;
  },
  AnyEventObject,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    context: {
      currentPage: string;
      currentPopup: string;
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>;

export type RoutingMachineService = ReturnType<typeof createRoutingService>;

// eslint-disable-next-line
export let routing_service: RoutingMachineService | null = null;

const createRoutingService = () => {
  return interpret(routingMachine, { execute: false })
    .onTransition((s) => {
      // eslint-disable-next-line camelcase
      routing_service?.execute(s);
    })
    .start();
};

// eslint-disable-next-line camelcase
routing_service = createRoutingService();

// eslint-disable-next-line camelcase, @typescript-eslint/no-explicit-any
(window as any).routing_service = routing_service;
